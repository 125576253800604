import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import throttle from 'lodash/throttle';
import { Logo, LogoProduct, Icon, IconType } from '@dealroadshow/uikit';

import { Link, TabLink as NextNavLink } from '@/Framework/Router/Next/Link';
import HeaderBanner from '@/ui/shared/components/Layout/HeaderBanner';
import Button, { variantTypes } from '@/ui/shared/components/Button';
import isDefined from '@/Framework/dataHelpers/isDefined';
import useElementSize from '@/Framework/hooks/useElementSize';

import interactionStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/interaction.scss';
import styles from './header.scss';

interface IMenuItem {
  key: string,
  title: string,
  path: string,
  isActive?: () => boolean,
}

interface IHeaderButtonProps {
  buttonProps: {
    className: string,
    variant: string,
    onClick: () => void,
  },
}

interface ILoginButtonProps {
  buttonProps: {
    variant: string,
    className: string,
    title: string,
    dataTest: string,
  },
  linkProps: {
    'data-test': string,
  },
}

interface IProps {
  logoProduct: LogoProduct,
  menu: IMenuItem[],
  loginUrl?: string,
  customLoginButton?: React.ComponentType<ILoginButtonProps>,
  headerButton?: React.ComponentType<IHeaderButtonProps>,
  logoClassName?: string,
  mobileMenuClassName: string,
}

const Header = ({
  logoProduct,
  menu,
  customLoginButton: CustomLoginButton,
  headerButton: HeaderButton,
  loginUrl,
  logoClassName,
  mobileMenuClassName,
}: IProps) => {
  const headerRef = useRef(null);
  const lastScrollPos = useRef(0);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const { height: headerHeight } = useElementSize('landingHeader');

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
    window.addEventListener('resize', onResize);

    return () => {
      document.removeEventListener('scroll', handleScroll, true);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // Hide or show the menu.
  const handleScroll = throttle(() => {
    setIsHeaderFixed(window.scrollY > 0);
    // Fix for throttled scroll on redirect
    if (!headerRef.current) {
      return;
    }

    const currentScroll = document.documentElement.scrollTop;
    const isSticky = currentScroll > headerRef.current.clientHeight;

    if (isSticky) {
      if (currentScroll > 0 && lastScrollPos.current <= currentScroll) {
        lastScrollPos.current = currentScroll;
        setIsHeaderVisible(false);
      } else {
        lastScrollPos.current = currentScroll;
        setIsHeaderVisible(true);
      }
    } else {
      setIsHeaderVisible(true);
    }
  }, 100);

  const onResize = throttle(() => {
    handleMobileMenuToggle(false);
  }, 100);

  const handleMobileMenuToggle = (toggle?: boolean) => {
    toggle = isDefined(toggle) ? toggle : !isMobileMenuOpened;
    setMobileMenuOpened(toggle);
    // IE11 Fix
    if (toggle) {
      document.body.classList.add(interactionStyles.nonScrollable);
    } else {
      document.body.classList.remove(interactionStyles.nonScrollable);
    }
  };

  const closeMenu = () => handleMobileMenuToggle(false);

  const handleMobileMenuLink = () => {
    if (isMobileMenuOpened) {
      handleMobileMenuToggle(false);
    }
  };

  const renderLoginButton = () => {
    const linkProps: ILoginButtonProps['linkProps'] = {
      'data-test': 'headerLogIn',
    };

    const buttonProps: ILoginButtonProps['buttonProps'] = {
      variant: variantTypes.outline,
      className: cn(styles.menuButton, styles.menuLoginButton),
      title: 'Log In',
      dataTest: 'logInButton',
    };

    if (CustomLoginButton) {
      return (
        <CustomLoginButton
          buttonProps={ buttonProps }
          linkProps={ linkProps }
        />
      );
    }

    if (loginUrl) {
      return (
        <Link
          to={ loginUrl }
          { ...linkProps }
        >
          <Button { ...buttonProps } />
        </Link>
      );
    }

    return null;
  };

  const renderHeaderButton = () => {
    if (!HeaderButton) {
      return null;
    }

    return (
      <HeaderButton
        buttonProps={ {
          className: cn(styles.menuButton, styles.menuCustomButton),
          variant: variantTypes.action,
          onClick: closeMenu,
        } }
      />
    );
  };

  const renderActions = () => (
    <>
      { renderLoginButton() }
      { renderHeaderButton() }
    </>
  );

  const renderMenu = (menu: IMenuItem[]) => menu.map((menuItem) => (
    <NextNavLink
      key={ menuItem.key }
      to={ menuItem.path }
      title={ menuItem.title }
      name={ `${ menuItem.key }NavLink` }
      className={ styles.menuLink }
      activeClassName={ styles.isMenuLinkActive }
      isExactActive
      { ...(menuItem?.isActive && { isActive: menuItem.isActive() }) }
      onClick={ handleMobileMenuLink }
    >
      { menuItem.title }
    </NextNavLink>
    ));

  const headerCls = cn(styles.animatedHeader, {
    [styles.isHeaderHidden]: !isHeaderVisible,
  });

  const menuClassName = cn(styles.mobileMenu, mobileMenuClassName, {
    [styles.menuOpened]: isMobileMenuOpened,
  });

  const logoImgClassName = cn(styles.logoImage, logoClassName);

  return (
    <>
      { isMobileMenuOpened && <div className={ styles.headerOverlay } onClick={ closeMenu } /> }
      { isHeaderFixed && <div style={ { height: headerHeight } } /> }
      <div
        className={ cn(styles.header, {
          [styles.headerFixed]: isHeaderFixed,
        }) }
        id="landingHeader"
        data-test="header"
      >
        <HeaderBanner isSticky={ false } />
        <div
          className={ headerCls }
          ref={ headerRef }
        >
          <div className={ styles.headerWrp }>
            <div className={ styles.headerContainer } data-test="desktopNav">
              <div>
                <div className={ styles.burger } onClick={ () => handleMobileMenuToggle() }>
                  <Icon type={ !isMobileMenuOpened ? IconType.hamburgerMenu : IconType.close } />
                </div>
                <div className={ styles.logo }>
                  <Link to="/" onClick={ closeMenu }>
                    <Logo
                      type={ Logo.TYPE.HORIZONTAL }
                      colorType={ Logo.COLOR_TYPE.DARK }
                      product={ logoProduct }
                      className={ logoImgClassName }
                    />
                  </Link>
                </div>
                <div className={ styles.divider } />
                <div className={ styles.menu }>
                  <div className={ styles.headerMenu }>{ renderMenu(menu) }</div>
                </div>
              </div>
              <div className={ styles.headerActions }>
                <div className={ styles.headerUserActions }>{ renderActions() }</div>
              </div>
            </div>
          </div>
          <div className={ menuClassName } data-test="mobileNav">
            <div>
              <div className={ styles.mobileHeaderMenu }>
                <div
                  className={ styles.closeMenuButton }
                  onClick={ closeMenu }
                >
                  <Icon type={ IconType.close } />
                </div>
                <div className={ styles.logo }>
                  <Link to="/" onClick={ closeMenu }>
                    <Logo
                      type={ Logo.TYPE.HORIZONTAL }
                      colorType={ Logo.COLOR_TYPE.WHITE }
                      product={ logoProduct }
                      className={ logoImgClassName }
                    />
                  </Link>
                </div>
              </div>
              <div>{ renderMenu(menu) }</div>
            </div>
            <div className={ styles.mobileActionsWrp }>{ renderActions() }</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
